import { inject, onMounted, onUnmounted, ref } from 'vue';

import { subscribeKey, unsubscribeKey } from '@drapejs/core';

import { channelKey } from '@/keys';

export default function () {
  const channel = inject(channelKey);
  const subscribe = inject(subscribeKey, () => Promise.resolve());
  const unsubscribe = inject(unsubscribeKey, () => Promise.resolve());

  const products = ref([]);

  function handler(data) {
    products.value = data?.recentlyVisitedProducts?.products || [];
  }

  onMounted(() => {
    subscribe(
      getRecentlyVisitedProductsCacheKey(channel?.value?.locale),
      handler,
      {
        immediate: true,
      }
    );
  });

  onUnmounted(() => {
    unsubscribe(
      getRecentlyVisitedProductsCacheKey(channel?.value?.locale),
      handler
    );
  });

  return { products };
}

export function getRecentlyVisitedProductsIdsCacheKey(locale: string) {
  return `__recentlyVisitedProductsIds:${locale}`;
}

export function getRecentlyVisitedProductsCacheKey(locale: string) {
  return `__recentlyVisitedProducts:${locale}`;
}
