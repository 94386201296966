import { onMounted, onBeforeUnmount } from 'vue';

export default function () {
  function useElementEventListener(target: any, event: any, callback: any) {
    onMounted(() => target.addEventListener(event, callback));
    onBeforeUnmount(() => target.removeEventListener(event, callback));
  }

  function useWindowEventListener(
    event: any,
    callback: any,
    executeOnMounted: boolean,
    args?: any
  ) {
    onMounted(() => {
      if (executeOnMounted) {
        args ? callback(args) : callback();
      }
      window.addEventListener(event, callback);
    });
    onBeforeUnmount(() => window.removeEventListener(event, callback));
  }

  return {
    useElementEventListener,
    useWindowEventListener,
  };
}
