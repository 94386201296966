<template>
  <line class="divider"></line>
</template>

<style>
.divider {
  display: block;
  background: var(--color-black);
  width: 100%;
  height: 1px;
}
</style>
